import Vue from 'vue';
import VueRouter from 'vue-router';
import /* webpackChunkName: "Home" */ Home from '../views/Home.vue';
import store from '../store/index'
import * as helpers from '../mixins/helpers';

Vue.use(VueRouter);

import DynamicPage from '../views/DynamicPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: {
      path: '/home'
    }
  },
  {
    path: '/services/service-reservation/:id(\\d+)',
    name: 'serviceReservation',
    component: () => import(/* webpackChunkName: "servicesResrvistion" */ '../views/serviceReservation.vue')
  },
  {
    path: '/news/read/:id(\\d+)/:title?',
    name: "NewsArticle",
    component: () => import(/* webpackChunkName: "NewsArtical" */ "../views/NewsArtical.vue"),
  },
  {
    path: '*',
    component: () => import('../components/misc/404.vue'),
    name: '404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (store.getters.controlledRoutes.length == 0) {
    getDynamicRoutes(to, next);
  } else {
    store.commit('changeAppLoading', false);
    next()
  }
})

function getDynamicRoutes(to, next) {
  store.dispatch('loadPages', {})
    .then(res => {
      let data = res.data.results.data;
      data = helpers.sortItems(data);
      data.forEach(el => {
        createAndAppendRoute(el);
      });
      store.commit('changeAppLoading', false)
      if (router.getRoutes().find(route => route.name == to.name)) {
        next(to.path);
      } else {
        console.log('error')
        next({ name: '404' });
      }
    });
};

function createAndAppendRoute(route) {
  let newRoute = {
    path: `/${route.path}`,
    component: DynamicPage,
    name: `${route.titles.en ? route.titles.en : route.titles}`,
    props: { page_id: route.id },
    meta: {
      page_id: route.id,
      names: route.titles.en ? { ...route.titles } : route.titles,
      status: route.status
    }
  };
  store.commit('addToDynamicRoutes', newRoute);
  router.addRoute(newRoute);
};

export default router;
