import Axios from "./embassyInc";

export default {
    actions: {
        subscribe({ commit }, payload) {
            console.log(payload);
            return new Promise((resolve, reject) => {
                Axios.post("/v1/website/subscribe/create", {
                    path: payload.path,
                    email: payload.email,
                }).then(res => {
                    if (res.status == 200) {
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });

        },
        // contactUs() {
        //     return new Promise((resolve, reject) => {
        //         Axios.post("/v1/website/contactus/create", {
        //             name: payload.name,
        //             email: payload.email,
        //             subject: payload.subject,
        //             description: payload.description,
        //         }).then(res => {
        //             if (res.status == 200) {
        //                 resolve(res);
        //             } else {
        //                 reject(res);
        //             }
        //         }).catch(err => {
        //             reject(err);
        //         });
        //     });
        // }
    }
};