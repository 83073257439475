<template>
  <div
    class="page-title text-center relative-sec py-5"
    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  >
    <div class="container">
      <div class="header-divider-vertical"></div>
      <div class="position-relative">
        <h2 class="fw-bold mt-5" v-if="title && title !== 'null'">{{ title }}</h2>
        <p class="fw-bold mx-auto" v-if="desc && desc!=='null'" v-html="desc"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "desc"],
};
</script>

<style>
</style>