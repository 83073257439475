<template>
  <div class="dynamic-page-container" v-if="!loading">
    <div class="container py-5 my-5" v-if="page.sections_count == 0">
      <div class="no-sections text-center py-5 my-5">
        <h3>
          {{ $t("noSections") }}
        </h3>
        <p class="desc">
          {{ $t("noSectionsDesc") }}
        </p>
      </div>
    </div>
    <div v-for="section in page.sections" :key="section.id" v-if="section.status&& section.custom_fields">
      <section
        class="hero-section-container mb-5"
        v-if="section.custom_fields.section_key == 'mainSlider'"
      >
        <HeroSection :section="section" />
      </section>
      <PageTitle v-if="section.custom_fields.section_key == 'pageHeader'" :title="section.title" :desc="section.description" />
      <section
        class="services-section-container"
        v-if="section.custom_fields.section_key == 'secondarySlider'"
      >
        <div class="hero-container mt-4" :class="[t_ar]">
          <div class="news-holder">
            <div class="container">
              <newsSwiper :section="section" />
            </div>
          </div>
        </div>
      </section>

      <section
        class="trending-section-container app-sizing"
        v-if="
          section.custom_fields.section_key == 'services'
        "
      >
        <TrendingServices :section="section" />
      </section>

      <section
        class="aboutEmbassy-section-container app-sizing"
        v-if="section.custom_fields.section_key == 'post_1'"
      >
        <AboutEmbassy :section="section" />
      </section>

      <section
        class="aboutEG-section-container app-sizing"
        v-if="section.custom_fields.section_key == 'post_2'"
      >
        <AboutEG :section="section" />
      </section>
      <section
        class="news-section-container app-sizing my-4"
        v-if="section.custom_fields.section_key == 'lastNews'"
      >
        <LastNewsSection :section="section" />
      </section>
      <section
        class="contactUs-section-container"
        v-if="section.custom_fields.section_key == 'contactUs'"
      >
        <ContactUs :useTitle="true" :section="section" />
      </section>

      <section
        class="contactUs-section-container"
        v-if="section.custom_fields.section_key == 'subscribe'"
      >
        <NewsLetter :useTitle="true" :section="section" />
      </section>


      <section
        class="about-eg-embassy-page"
        v-if="section.title !== 'null'&&section.custom_fields.section_key == 'article'"
      >
        <AboutEgypt :useTitle="true" :section="section" />
      </section>

      <section
        class="contactUs-section-container minHeight-abouteg"
        v-if="
        section.title !== 'null'&&  section.custom_fields.section_key == 'article_2'
        "
      >
        <div class="about-eg-embassy-page container">
          <EmbassyHistory :section="section" />
        </div>
      </section>
    </div>
  </div>
  <div class="container py-5 my-5" v-else>
    <div class="loader my-5 mx-auto"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AboutEG from "../components/AboutEG.vue";
import HeroSection from "../components/HeroSection.vue";
import NewsSwiper from "../components/newsSwiper.vue";
import TrendingServices from "../components/TrendingServices.vue";
import AboutEmbassy from "../components/AboutEmbassy.vue";
import NewsLetter from "../components/misc/NewsLetter.vue";
import LastNewsSection from "../views/LastNews.vue";
import EmbassyHistory from "../components/EmbassyHistory.vue";
import ContactUs from "../views/ContactUs.vue";
import helpers from "../mixins/helpers";
import AboutEgypt from "../views/AboutEgypt.vue";
import PageTitle from "../components/misc/PageTitle.vue";

export default {
  components: {
    AboutEgypt,
    AboutEG,
    NewsSwiper,
    AboutEmbassy,
    TrendingServices,
    HeroSection,
    NewsLetter,
    LastNewsSection,
    EmbassyHistory,
    ContactUs,
    PageTitle
  },
  watch: {
    $route: function () {
      this.loading = true;
      this.loadVPage({ id: this.$route.meta.page_id }).then((res) => {
        helpers.sortItems(this.page.sections);
        this.loading = false;
      });
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    this.loadVPage({ id: this.$route.meta.page_id }).then((res) => {
      setTimeout(() => {
        helpers.sortItems(this.page.sections);
        this.loading = false;
      }, 250);
    });
  },

  methods: {
    ...mapActions(["loadVPage"]),
  },
  computed: {
    ...mapGetters(["controlledRoutes", "page"]),
  },
};
</script>
