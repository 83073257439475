<template>
  <div class="embassy-container" v-if="section">
    <div class="container my-5">
      <div :class="['row', 'mt-5', 'mb-5', fl_ar]">
        <div
          class="col-md-6"
          :class="lang == 'ar' ? 'text-start' : 'text-end'"
          v-if="section.media_url"
        >
          <img :src="section.media_url" class="intro-img" />
        </div>
        <div :class="[section.media_url ? 'col-md-6' : 'col-12 mt-5']">
          <h3
            class="header-main d-flex align-items-center my-5"
            :class="fl_ar_rev"
            v-if="section.titles || section.title"
          >
            {{ section.titles ? section.titles[lang] : section.title }}
            <span class="header-divider d-inline-block mx-1 mt-1"></span>
          </h3>
          <div
            :class="['artical', dir_ar]"
            v-if="section.descriptions || section.description"
          >
            <p
              v-html="
                section.descriptions ? section.descriptions[lang] : section.description
              "
            ></p>
            <button
              class="btn-pri"
              v-if="section.custom_fields.action_target"
              @click="openTarget(section.custom_fields)"
            >
              {{ section.custom_fields.action_text[lang] || $t("read more") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["section"],
  methods: {
    ...mapActions(["loadVSection"]),
    goToAboutEgEmbassy() {
      this.$router.push({ name: "AboutEmbassy" });
    },
  },
  computed: {
    ...mapGetters(["getVSection"]),
  },
};
</script>
