<template>
  <div class="eg-container my-5">
    <div class="container">
      <div
        class="eg-content"
        :class="ar ? 'eg-content' : 'eg-content-ar'"
        :style="{
          backgroundImage: `url(${overlay}),  url(${
            section.media_url || bg
          })`,
        }"
      >
        <h3
          class="header-main-white d-flex align-items-center my-5"
          :class="fl_ar_rev"
        >
          {{ section.titles?section.titles[lang]:section.title }}
          <span class="header-divider d-inline-block mx-1 mt-1"></span>
        </h3>
        <h2
          v-if="section.descriptions||section.description"
          class="eg-text"
          :class="t_ar"
          v-html="section.descriptions?section.descriptions[lang]:section.description"
        ></h2>
        <button
          type="button"
          v-if="section.custom_fields.action_target"
          :class="['btn-pri', dir_ar == 'dir-rtl' ? 'ms-auto' : 'me-auto']"
          @click="openTarget(section.custom_fields)"
        >
          {{ section.custom_fields.action_text[lang] }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AboutEgypt from "../views/AboutEgypt.vue";

export default {
  components: { AboutEgypt },
  data() {
    return {
      bg: require("@/assets/images/about.png"),
    };
  },
  props: ["section"],
  methods: {
    ...mapActions(["loadVSection"]),
    goToAboutEgypt(link) {
      this.$router.push({ path: link });
    },
  },
  computed: {
    ...mapGetters(["getVSection"]),
  },
};
</script>