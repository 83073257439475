<template>
  <section class="lastnews-page">
    <div :class="['container', dir_ar]">
      <div class="d-flex align-items-center justify-content-between">
        <div>

          <h3 v-if="!section.custom_fields.view_all" class="header-main">
            {{ section.title }}
            <span class="header-divider d-inline-block mx-1 mt-1"></span>
          </h3>
        <p v-if="section.description && !section.custom_fields.view_all" v-html="section.description">

        </p>
        </div>
        <div
          :class="['more-btn', dir_ar == 'dir-rtl' ? 'me-auto' : 'ms-auto']"
          v-if="
            section.custom_fields.action_target &&
            section.custom_fields.action_text &&
            section.custom_fields.action_text[lang]
          "
        >
          <button class="btn" @click="openTarget(section.custom_fields)">
            {{ section.custom_fields.action_text[lang] || $t("show all") }}
          </button>
        </div>
      </div>
      <div class="news mt-3" v-if="!loading">
        <NewsCard
          v-for="(news, index) in newsList"
          :key="index"
          :news="news"
          @readMore="
            readMore(
              news.id,
              news.titles && news.titles[lang]
                ? news.titles[lang].split(' ').join('-')
                : news.title.split(' ').join('-')
            )
          "
        />
      </div>
      <div class="container my-5" v-else-if="loading">
        <div class="loader mx-auto my-5"></div>
      </div>
    </div>
    <div :class="['container', dir_ar]" v-if="this.section.custom_fields.view_all">
      <!-- v-if="rows > 1" -->
      <Pagination
        v-model="page"
        :records="rows"
        @paginate="getPageCount"
        :per-page="10"
        :options="paginationOptions"
      />
    </div>
  </section>
</template>

<script>
import PageTitle from "../components/misc/PageTitle.vue";
import NewsCard from "../components/misc/NewsCard.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["section"],
  components: {
    PageTitle,
    NewsCard,
  },
  data() {
    return {
      loading: false,
      page: 1,
      rows: 0,
      paginationOptions: {
        texts: {
          count: this.$t(`ShowingText`),
        },
      },
    };
  },
  computed: {
    ...mapGetters(["getAllNews"]),
    newsList() {
      if (!this.section.custom_fields.view_all) {
        const list = this.getAllNews.splice(0, 3);
        return list;
      } else {
        return this.getAllNews;
      }
    },
  },
  mounted() {
    this.getPageCount();
  },
  methods: {
    ...mapActions(["loadNews", "loadVPage"]),
    readMore(index, title) {
      this.$router.push({
        name: "NewsArticle",
        params: { id: index, title: title },
      });
    },
    getPageCount() {
      this.loading = true;
      this.loadNews({ page: this.page })
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            this.rows = res.data.results.total;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.err = err && err.response ? err.response.data.results.message : err;
        });
    },
  },
};
</script>

<style></style>
