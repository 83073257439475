<template>
  <div class="contact-us-page relative-sec mt-4">
    <div class="container">
      <div :id="`map-box-${section.id}`" v-if="section.custom_fields.map_lat&&section.custom_fields.map_lng" class="map-box"></div>
      <div :class="['row', 'mt-3', 'mb-5', dir_ar, fl_ar]">
        <div class="col-12 col-md-8 mb-4 mb-md-0">
          <ContactUs :useTitle="false" />
        </div>
        <div class="col-12 col-md-4">
          <div
            :class="['card-info', 'd-flex', 'flex-column', 'p-4', dir_ar]"
            v-if="infoBox.length"
          >
            <div
              class="row my-1 align-items-center"
              v-for="(info, index) in infoBox"
              :key="index"
            >
              <div class="col-2 p-0 align-items-center d-flex">
                <i :class="info.icon"></i>
              </div>
              <div class="col-10 p-0 align-items-start d-flex">
                <a
                  :href="info.link"
                  class="fw-bold m-0 no-link-style text-underline"
                  target="_blank"
                  :dir="index != 0 ? '' : 'ltr'"
                >
                  {{
                    index == 0
                      ? info.text.replace(/(\d{2})(\d{2})/, "$1 $2  ")
                      : info.text
                  }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "../components/misc/PageTitle.vue";
import ContactUs from "../components/ContactUs.vue";
import { mapGetters } from "vuex";

export default {
  props: ["section"],
  components: {
    PageTitle,
    ContactUs,
  },
  data() {
    return {
      infoBox: [
        {
          icon: "fa fa-phone",
          text: "+49304775470",
          link: "tel:+49304775470",
        },
        {
          icon: "fa fa-envelope",
          text: "support@egyption-embassy.de",
          link: "mailto:support@egyption-embassy.de",
        },
        {
          icon: "fa fa-map-marker",
          text: "Stauffenbergstr. 6-7 10785 Berlin",
        },
      ],
    };
  },
  mounted() {
    if (this.getSettings) {
      this.infoBox[0].text = "+" + this.getSettings.phone;
      this.infoBox[0].link = `tel:+${this.getSettings.phone}`;
      this.infoBox[1].text = this.getSettings.email;
      this.infoBox[1].link = `mailto:${this.getSettings.email}`;
      this.infoBox[2].text = this.getSettings.address;
      if (
        this.section.custom_fields.map_lat &&
        this.section.custom_fields.map_lng
      ) {
        setTimeout(() => {
          const mapElement = document.getElementById(`map-box-${this.section.id}`);
          if(!mapElement) return;
            const map = new google.maps.Map(mapElement, {
              center: this.markerPos,
              zoom: 8,
              mapId: "12a8a4acf250f911",
              zoomControl: true,
              mapTypeControl: false,
              mapTypeId: "terrain",
              disableDefaultUI: true,
            });
            const marker = new google.maps.Marker({
              position: this.markerPos,
              map,
              animation: google.maps.Animation.DROP,
            });
            marker.addListener("load", toggleBounce);
            marker.addListener("click", () => {
              map.setZoom(8);
            });
            function toggleBounce() {
              if (marker.getAnimation() !== null) {
                marker.setAnimation(null);
              } else {
                marker.setAnimation(google.maps.Animation.BOUNCE);
              }
            }
        }, 1000);
      }
    }
  },
  computed: {
    ...mapGetters(["getSettings"]),
    markerPos() {
      return {
        lat: parseInt(this.section.custom_fields.map_lat),
        lng: parseInt(this.section.custom_fields.map_lng),
      };
    },
    centerPos() {
      return {
        lat: parseInt(this.section.custom_fields.map_lat),
        lng: parseInt(this.section.custom_fields.map_lng),
      };
    },
  },
};
</script>

<style></style>
