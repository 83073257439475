<template>
  <div id="app" v-if="!appLoading">
    <TweetsBox />
    <AppHeader
      :openExpandNav="openExpandNav"
      :closeExpandNav="closeExpandNav"
    />
    <div class="">
      <transition
        mode="out-in"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <router-view v-slot="{ Component }">
          <component :is="Component"> </component>
        </router-view>
      </transition>
    </div>
    <AppFooter />
  </div>
  <div class="container py-5 my-5" v-else>
    <div class="loader my-5 mx-auto"></div>
  </div>
</template>

<script>
import AppHeader from "@/components/navigation/AppHeader";
import AppFooter from "@/components/navigation/AppFooter";
import { mapActions, mapGetters } from "vuex";
import TweetsBox from "./components/TweetsBox.vue";
export default {
  components: {
    AppFooter,
    AppHeader,
    TweetsBox
  },
  data() {
    return {
      myModal: "",
      openedModal: {},
      loading: true,
      lastScrollTop: 0,
    };
  },
  created() {
    // console.log(this.$router.getRoutes())
    if (this.storageGet("up_ege")) {
      // console.log(this.storageGet('up_ege'))
      this.$i18n.locale = this.storageGet("up_ege").lang
        ? this.storageGet("up_ege").lang
        : this.storageGet("up_ege");
    } else {
      if (this.lang) {
        this.storageSet("up_ege", this.lang);
        // console.log(this.storageGet('up_ege'))
      } else {
        this.$i18n.locale = "ar";
      }
    }
    this.$loadScript("../../js/bootstrap.bundle.js").then((res) => {
      // this.myModal = new bootstrap.Modal(document.getElementById('projectModal'));
    });
    // window.addEventListener("scroll", this.handleScrollDirection, false);

    this.loadSettings().then((res) => {
      if (res.status == 200) {
        console.log();
        document.title = res.data.results.AppName;
        var link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href = res.data.results.favicon;
        document
          .querySelector('meta[name="title"]')
          .setAttribute("content", res.data.results.site_meta_title);
        document
          .querySelector("meta[name='msapplication-TileImage']")
          .setAttribute("content", res.data.results.logo);
        document
          .querySelector("meta[property='twitter:card']")
          .setAttribute("content", res.data.results.logo);
        document
          .querySelector("meta[property='og:site_name']")
          .setAttribute("content", res.data.results.AppName);
        document
          .querySelector("meta[property='og:title']")
          .setAttribute("content", res.data.results.site_meta_title);
        document
          .querySelector("meta[property='twitter:title']")
          .setAttribute("content", res.data.results.site_meta_title);
        document
          .querySelector("meta[property='og:description']")
          .setAttribute("content", res.data.results.site_meta_description);
        document
          .querySelector("meta[property='og:image']")
          .setAttribute("content", res.data.results.logo);
        document
          .querySelector("meta[property='og:image']")
          .setAttribute("content", res.data.results.logo);
        const url = res.data.results.social_media_links.find(
          (so) => so.name == "baseURL"
        );
        document
          .querySelector("meta[property='og:url']")
          .setAttribute("content", url?.link);
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", res.data.results.site_meta_description);
        document
          .querySelector("meta[property='twitter:url']")
          .setAttribute("content", url?.link);
        document
          .querySelector('meta[property="twitter:description"]')
          .setAttribute("content", res.data.results.site_meta_description);
      }
    });
    this.loadPages({});
    document.body.classList.add(this.lang);
  },
  methods: {
    ...mapActions(["loadSettings", "loadPages"]),
    handleResize() {
      this.innerWidth = window.innerWidth;
      // console.log(this.width)
    },
    calcLoading(isLoaded) {
      this.loading = !isLoaded;
    },
    handleScrollDirection() {
      var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      if (st > this.lastScrollTop) {
        // this.sticky = false;
      } else {
        // this.sticky = true;
      }
      this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    },
    openModal(item) {
      this.openedModal = { ...item };
      this.myModal.show();
    },
    closeModal() {
      this.openedModal = {};
      this.myModal.hide();
    },
    openExpandNav() {
      let overlay = document.getElementById("overlay");
      let nav = document.getElementById("expandnav");
      if (overlay && nav) {
        nav.setAttribute("data-expanded", true);
        nav.classList.add("active");
        overlay.classList.add("active");
        this.$forceUpdate();
      }
    },
    closeExpandNav() {
      let overlay = document.getElementById("overlay");
      let nav = document.getElementById("expandnav");
      if (overlay && nav) {
        console.log("herer");
        nav.setAttribute("data-expanded", false);
        nav.classList.remove("active");
        overlay.classList.remove("active");
        this.$forceUpdate();
      }
    },
  },
  computed: {
    ...mapGetters(["appLoading", "controlledRoutes"]),
  },
};
</script>

<style lang="scss">
@import "./assets/scss/bootstrap.css";
@import "./assets/scss/all.scss";
@import "./assets/font/font-awesome-4.7.0/css/font-awesome.min.css";
@import "./assets/scss/swiper-bundle";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");
.animate__animated.fadeIn {
  --animate-duration: 0.5s;
}
.animate__animated.fadeOut {
  --animate-duration: 0.2s;
}
</style>
