<template>
  <div>

  <nav id="appHeader">
    <div class="sub-header" v-if="getSocials">
      <div class="container d-flex align-items-center justify-content-between">
        <div class="links">
          <div class="footer-social-container d-flex">
            <div
              v-show="social.name != 'baseurl'"
              v-for="social in getSocials"
              :key="social.id"
            >
              <a class="btn nobg btn-icon-social" :href="social.link" target="_blank"
                ><i class="fa" :class="`fa-${social.name}`"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="appheader-mob" id="appheader-container" v-if="smallScreens" :class="fl_ar">
      <div class="ind-icon d-flex" :class="fl_ar_rev">
        <LanguageChanger padd="p-0" />
        <span @click="toggleNavigation" class="pointer shadow mx-2 btn-pri p-1">
          <mdicon name="view-headline" />
        </span>
      </div>
      <div class="app-logo mx-2">
        <!-- <router-link :to="{ name: 'Home' }"> -->
        <img
          :src="getSettings ? getSettings.logo || logo : logo"
          alt="App Logo"
          @click="$router.push('/')"
        />
        <!-- <p class="desc">
              {{appName}}
            </p> -->
        <!-- </router-link> -->
      </div>
      <div class="overlay" id="overlay" @click="closeExpandNav"></div>
      <div class="expandnav-container" :data-expanded="false" id="expandnav">
        <ul :class="['expandnav-items-container', smallScreens ? dir_ar : '']">
          <li
            class="expandnav-item-container"
            v-for="(item, index) in allRoutes"
            :key="index"
            v-show="item.meta.status"
          >
            <router-link
              active-class="active"
              exact
              :to="{ name: item.name }"
              @click="toggleNavigation"
              class="expandnav-link"
            >
              {{ item.meta ? item.meta.names[lang] : item.name }}
            </router-link>
          </li>

          <button class="btn-nav-mob btn-pri d-none" type="button">
            {{ $t("login") }}
          </button>
        </ul>
      </div>
    </div>

    <div class="appheader-container" id="appheader-container" v-if="largeScreens">
      <div class="container" :class="fl_ar">
        <div :class="['appheader-side', fl_ar_rev]">
          <LanguageChanger :padd="false" class="mx-1" />
          <!-- <div
              :class="[
                'fixed-menu-toggler',
                dir_ar == 'dir-rtl' ? 'dir-rtl' : 'dir-ltr',
                'pointer',
              ]"
            >
              <span></span>
              <span></span>
              <span></span>
            </div> -->
          <img
            @click="toggleFixedMenu"
            class="pointer"
            style="transform: rotate(180deg)"
            :src="require('../../assets/images/menu.png')"
            width="25px"
            alt=""
          />
          <div></div>
          <div class="appheader-side-buttons">
            <!-- <button class="btn-nav d-none" type="button">
              {{ $t("login") }}
            </button> -->
          </div>
        </div>

        <div class="appheader-links-container">
          <ul class="appheader-items-container" :class="fl_ar_rev">
            <li
              class="appheader-item-container"
              v-for="(item, index) in allRoutes"
              :key="index"
              v-show="item.meta.status"
            >
              <router-link
                :to="{ name: item.name }"
                active-class="active"
                exact
                class="expandnav-link"
              >
                {{
                  item.meta
                    ? item.meta.names.en
                      ? item.meta.names[lang]
                      : item.name
                    : item.name
                }}
              </router-link>
            </li>
          </ul>
        </div>

        <div :class="['app-logo', dir_ar, 'pointer']" @click="$router.push('/')">
          <img alt="App Logo" :src="logo" />
          <p class="desc">
            {{ getSettings ? getSettings.AppName || appName : appName }}
          </p>
        </div>
      </div>
    </div>

  </nav>
  <transition name="fade">
    <fixed-menu v-if="fixedMenu" @close="toggleFixedMenu"></fixed-menu>
  </transition>
</div>

</template>

<script>
import LanguageChanger from "@/components/navigation/LanguageChanger";
import FixedMenu from "../misc/FixedMenu.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    LanguageChanger,
    FixedMenu,
  },
  data() {
    return {
      fixedMenu: false,
      logo: require("../../assets/images/logo/logo.png"),
      appName: this.$t("appName"),
      appheaderLinks: [],
    };
  },
  watch: {
    fixedMenu() {
      if (this.fixedMenu) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    },
    $route() {
      this.closeExpandNav();
    },
  },
  props: ["closeExpandNav", "openExpandNav"],
  methods: {
    toggleFixedMenu() {
      this.fixedMenu = !this.fixedMenu;
    },
    goToSection(item) {
      this.$router.push({ name: item.link });
    },
    toggleNavigation() {
      let nav = document.getElementById("expandnav");
      if (nav) {
        nav.getAttribute("data-expanded") == "true"
          ? this.closeExpandNav()
          : this.openExpandNav();
      }
    },
  },
  computed: {
    ...mapGetters(["controlledRoutes", "getSettings", "getSocials"]),
    allRoutes() {
      return this.controlledRoutes;
    },
  },
  mounted() {
    const appHeader = document.getElementById("appHeader");
    const actualHeader = document.getElementById("appheader-container");
    window.addEventListener("scroll", () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop <= actualHeader.offsetHeight) {
        appHeader.classList.remove("active");
      } else {
        appHeader.classList.add("active");
      }
    });
  },
};
</script>
