<template>
  <div :class="['trending-container', 'my-5', dir_ar]" >
    <div class="container">
      <div
        class="d-flex align-items-center my-5"
        v-if="!this.section.custom_fields.view_all"
      >
      <div>

        <h3 class="header-main">
          {{ section.title }}
          <span class="header-divider d-inline-block mx-1 mt-1"></span>
        </h3>
        <p v-if="section.description" v-html="section.description">

        </p>
      </div>
        <div
          :class="['more-btn', dir_ar == 'dir-rtl' ? 'me-auto' : 'ms-auto']"
          v-if="section.custom_fields.action_target && section.custom_fields.action_text&& section.custom_fields.action_text[lang]"
        >
          <button class="btn"
          @click="openTarget(section.custom_fields)"
          >
            {{ section.custom_fields.action_text[lang] || $t("show all") }}
          </button>
        </div>
      </div>
      <div v-if="!loading" class="trending-container">
        <div class="trending-cards row">
          <TrendingServiceCard
            v-for="(service, index) in listServices"
            v-if="service"
            :card="service"
            @activeCard="activeCard(index)"
            :key="service.id"
            :elid="index"
            :isActive="truthArray[index] == true"
            @reservation="serviceReservation(service.id)"
          ></TrendingServiceCard>
        </div>
      </div>
      <div class="py-5 my-5" v-else-if="loading">
        <div class="loader my-5 mx-auto"></div>
      </div>
      <div v-if="this.section.custom_fields.view_all">
        <!-- v-if="rows > 1" -->
        <Pagination
          v-model="page"
          :records="rows"
          @paginate="getPageCount"
          :per-page="10"
          :options="paginationOptions"
          />
      </div>
    </div>
  </div>
</template>

<script>
import TrendingServiceCard from "./misc/TrendingServiceCard.vue";
import { mapGetters, mapActions } from "vuex";
import PageTitle from "./misc/PageTitle.vue";
export default {
  components: {
    TrendingServiceCard,
    PageTitle,
  },
  data() {
    return {
      truthArray: [],
      err: "",
      rows: 0,
      page: 1,
      loading: false,
      paginationOptions: {
        texts: {
            count: this.$t(`ShowingText`)
          }
      }
    };
  },
  mounted() {
    this.getPageCount();
  },
  props: ["section"],
  methods: {
    ...mapActions(["getServices"]),
    activeCard(index) {
      let otherels = [...document.getElementsByClassName("trending-card")];
      otherels.forEach((el) => {
        el.classList.remove("active");
        this.truthArray.push(false);
      });
      let ele = document.getElementById(index);
      ele.classList.toggle("active");
      for (let i = 0; i < this.truthArray.length; i++) {
        if (i == index) {
          this.truthArray[i] = true;
        } else {
          this.truthArray[i] = false;
        }
      }
      this.$forceUpdate();
    },
    serviceReservation(serviceId) {
      this.$router.push({
        name: "serviceReservation",
        params: { id: serviceId },
      });
    },
    getPageCount() {
      this.loading = true;
      this.getServices({ page: this.page })
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            this.rows = res.data.results.total;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.err = err && err.response ? err.response.data.results.message : err;
        });
    },
  },
  computed: {
    ...mapGetters(["popularTrendingCards", "services", "service", "appLoading"]),
    listServices() {
      if (!this.section.custom_fields.view_all) {
        const list = this.services.slice(0,4)
        return list;
      } else {
        return this.services;
      }
    },
  },
};
</script>
