import Axios from "./embassyInc";

export default {
    state: {
        sections: null,
        viewSection: null,
        dynamicRoutes: [],
        
    },
    mutations: {
        setSections(state, sectionsPayload) {
            state.sections = sectionsPayload;
        },
        setVSection(state, vSectionPayload) {
            state.viewSection = vSectionPayload;
        },
        
    },
    getters: {
        getSections(state) {
            return state.sections;
        },
        getVSection(state) {
            return state.viewSection;
        },
    },
    actions: {
        loadSections({ commit }) {
            return new Promise((resolve, reject) => {
                Axios.post("/v1/website/sections",{
                    status: 1
                }).then(res => {
                    if (res.data.results != null) {
                        commit("setSections", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        },
        loadVSection({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/v1/website/sections/show/${payload.id}`).then(res => {
                    if (res.data.results != null) {
                        commit("setVSection", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        }
    }
};