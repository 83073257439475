<template>
  <div :class="['contact-us', dir_ar]">
    <div class="container">
      <div class="title text-center fw-bold" v-if="useTitle">
        <h2 class="text-center relative-sec mt-5">
          <div class="header-divider-vertical"></div>
          {{$t("contactUs")}}
        </h2>
      </div>
      <form @submit.prevent="submitMsg" class="form-container">
        <div class="input mb-2">
          <label for="username">{{$t('full Name')}}</label>
          <input
          :disabled="loading"
          required
            type="text"
            id="username"
            v-model="formData.name"
            :placeholder="$t('full Name')"
          />
        </div>
        <div class="input mb-2">
          <label for="email">{{$t('emailPlace')}}</label>
          <input
          :disabled="loading"
          required
            type="text"
            id="email"
            v-model="formData.email"
            placeholder="example@example.com"
          />
        </div>
        <div class="input mb-2">
          <label for="username">{{$t('Subject')}}</label>
          <input
          :disabled="loading"
          required
            type="text"
            id="username"
            v-model="formData.subject"
            :placeholder="$t('Enter subject')"
          />
        </div>
        <div class="input mb-2 mb-0">
          <label for="message">{{$t('msgContent')}}</label>
          <textarea
          :disabled="loading"

          required
            id="message"
            v-model="formData.message"
            :placeholder="$t('msgPlace')"
          ></textarea>
        </div>
        <div class="input mb-2 pb-2 mx-auto my-0">
          <p v-if="msgSent">{{ msgSent }}</p>
          <p v-if="msgError" class="p-0 m-0">
            <span>{{msgError}}</span>
            <ul class="p-0 list-group m-0">
              <ol v-for="(err, i) in errors" :key="i">{{i+1+' - ' +err}}</ol>
            </ul>
          </p>
        </div>
        <div class="input mt-0">
          <button type="submit" :disabled="loading" class="d-flex align-items-center justify-content-center w-100 btn-pri btn submit">
            {{$t(loading?'Sending your message':'sendMsg')}}{{ loading?'...':'' }}
    <span class="loader-btn loader-sm" v-if="loading"></span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: ["useTitle"],
  data() {
    return {
      formData: {
        name: "",
        email: "",
        message: "",
        subject: "",
      },
      msgSent: "",
      msgError: "",
      errors: [],
      loading: false
    };
  },
  methods: {
    ...mapActions(["sendMessage"]),
    submitMsg() {
      this.loading = true;
      this.sendMessage(this.formData)
        .then((res) => {
          if (res.status == 200) {
            this.msgError = "";
            this.msgSent =
              "تم استلام رسالتك، سنقوم بالرد في وقت قصير، شكرا لتواصلك معنا.";
            this.formData = {
              name: "",
              email: "",
              message: "",
              subject: "",
            };
            setTimeout(() => {
              this.msgSent = "";
            }, 5000);
          }
      this.loading = false;
        })
        .catch((err) => {
      this.loading = false;
          if (err.response.status == 422) {
            this.msgSent = "";
            this.msgError = `للأسف حدث خطاء، رقم الخطاء:${err.response.status}`;
            this.errors = err.response.data.results.messages_array;
            setTimeout(() => {
              this.msgError = "";
            }, 10000);
          }
        });
    },
  },
};
</script>