import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      // appabr: 'sj',
      // appLoading: true,
      appabr: "ege",
      sortTemp: [],
      innerWidth: window.innerWidth,
    };
  },
  created() {
    // if(this.storageGet('up_cc')) {
    //   this.$i18n.locale = this.storageGet('up_cc').lang;
    // }
    window.addEventListener("resize", () => {
      this.innerWidth = window.innerWidth;
    });
  },
  destroyed() {
    window.removeEventListener("resize", () => {
      this.innerWidth = window.innerWidth;
    });
  },
  methods: {
    sortCategories(categories) {
      this.sortTemp = categories.sort((a, b) => {
        parseInt(a.ord) - parseInt(b.ord);
      });
      this.sortTemp.forEach((el) => {
        if (el.childs.length > 1) {
          el.childs.sort((a, b) => {
            parseInt(a.ord) - parseInt(b.ord);
          });
        }
      });
    },
    formateDateAndTime(date, type) {
      if (type == "full") {
        return new Date(date).toLocaleString(this.lang, {
          day: "2-digit",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
      } else if (type == "date") {
        return new Date(date).toLocaleString(this.lang, {
          day: "2-digit",
          month: "long",
          year: "numeric",
        });
      } else if (type == "time12") {
        return new Date(date).toLocaleString(this.lang, {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
      } else if (type == "time24") {
        return new Date(date).toLocaleString(this.lang, {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });
      }
    },
    openLink(link) {
      if (link.startsWith("http")) {
        window.open(link, "_blank");
      } else {
        this.$router.push(link);
      }
    },
    openTarget(data) {
      if (data.action_method === "page") {
        const path = data.action_target.path;
        this.$router.push(`${path.startsWith("/") ? path : "/" + path}`);
      } else if (data.action_method === "news") {
        const title = data.action_target.titles
          ? data.action_target.titles[this.lang]
          : data.action_target.title;
        this.$router.push({
          name: "NewsArticle",
          params: {
            id: data.action_target.id,
            title: title,
          },
        });
      } else if (data.type === "news") {
        this.$router.push({
          name: "NewsArticle",
          params: {
            id: data.target,
            title: data.target,
          },
        });
      } else if (data.action_method === "service"||data.type === "service") {
        this.$router.push({
          name: "serviceReservation",
          params: {
            id: data.target||data.action_target?.id,
          },
        });
      }else if (data.action_method === "url"||data.type === "url"){
        this.openLink(data.target||data.action_target);
      }else if(data.type === "page"){
        const page = this.controlledRoutes.find(page => page.meta.page_id == data.target);
        this.$router.push({name:page.name});
      }
    },
    parseString(string) {
      if (typeof string == "string" && string.length <= 9) {
        if (string == "undefined") {
          return false;
        } else {
          return JSON.parse(string);
        }
      } else {
        return string;
      }
    },
  },
  computed: {
    ...mapGetters([
      'controlledRoutes',
      "getSettings"
    ]),

    overlay: () => require("@/assets/images/overlay.png"),
    width() {
      if (this.innerWidth < 600) {
        return "sm";
      } else if (this.innerWidth < 768) {
        return "md";
      } else if (this.innerWidth < 992) {
        return "lg";
      } else if (this.innerWidth < 1200 && this.innerWidth >= 1400) {
        return "xl";
      } else {
        return "xxl";
      }
    },
    smallScreens() {
      return this.width == "sm" || this.width == "md";
    },
    midScreens() {
      return (
        this.width == "sm" ||
        this.width == "md" ||
        this.width == "lg" ||
        this.width == "xl"
      );
    },
    largeScreens() {
      return this.width == "lg" || this.width == "xl" || this.width == "xxl";
    },
    // isloggedIn() {
    //   return this.storageGet('u_cc');
    // },
    sortedCategories() {
      return this.sortTemp;
    },
    placeholder() {
      return require("../assets/images/logo/logo.png");
    },
    accountplaceholder() {
      return require("../assets/images/download.png");
    },
    t_ar() {
      return this.$i18n.locale == "ar" ? "t-end" : "t-start";
    },
    t_ar_rev() {
      return this.$i18n.locale == "ar" ? "t-start" : "t-end";
    },
    fl_ar() {
      return this.$i18n.locale == "ar" ? "fl-row" : "fl-reverse";
    },
    fl_ar_rev() {
      return this.$i18n.locale == "ar" ? "fl-reverse" : "fl-row";
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_rev() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
    dir_ar() {
      return this.$i18n.locale == "ar" ? "dir-rtl" : "dir-ltr";
    },
    lang() {
      return this.$i18n.locale;
    },
    ar() {
      return this.$i18n.locale == "ar";
    },
    routes() {
      return this.$router.getRoutes();
    },
  },
};
