import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import mdiVue from 'mdi-vue/v2';
import * as mdijs from '@mdi/js';
import i18n from './i18n';
import LoadScript from "vue-plugin-load-script";
import VueSocialSharing from 'vue-social-sharing';
// import axios from 'axios';
import crypto from 'vue-cryptojs'
import Pagination from 'vue-pagination-2';
import helpers from './mixins';
import storage from './mixins/storage';
// key: "",
Vue.use(VueSocialSharing);
// axios.defaults.baseURL = "";
Vue.use(mdiVue, {
  icons: mdijs
});
Vue.component('Pagination', Pagination)
Vue.use(LoadScript);
Vue.use(i18n);
Vue.use(crypto)

Vue.config.productionTip = false;

Vue.mixin(helpers);
Vue.mixin(storage);

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),

}).$mount('#app');
