<template>
  <div class="langChanger-container dropdown m-0">
    <button
      :class="['btn-nav-lang', padd ? '' : 'px-1',  dir_ar, 'm-0']"
      type="button"
      style="color: #000; white-space: nowrap"
      id="accountDropdown"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <span > {{$t(lang)}} </span>
    </button>
    <ul :class="['dropdown-menu', dir_ar]" aria-labelledby="dropdownMenuButton1">
      <li
        v-for="(availableLang, i) in availableLangs"
        :key="i"
        v-show="availableLang != lang"
        :class="dir_ar"
      >
        <a
          class="dropdown-item dropdown-link"
          :class="['d-flex',dir_ar == 'dir-rtl'? fl_ar_rev:'', dir_ar == 'dir-rtl'? 'justify-content-end':'justify-content-end']"
          @click="changeLang(availableLang)"
        >
          <span>{{$t(availableLang)}}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["padd"],
  data() {
    return {};
  },
  methods: {
    currentLangImg() {
      return require(`../../assets/images/flags/${this.lang}circle.png`);
    },
    LangImg(availablelang, i, needIndex) {
      if (needIndex) {
        return i;
      } else {
        return require(`../../assets/images/flags/${availablelang}circle.png`);
      }
    },
    changeLang(lang) {
      if (lang !== this.lang) {
        let newPref = lang;
        this.storageSet('up_ege', newPref)
        this.$router.go();
        // this.$i18n.locale = lang;
      }
    },
  },
  computed: {
    availableLangs() {
      return this.$i18n.availableLocales;
    },
  },
};
</script>
