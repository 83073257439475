<template>
  <div
    :class="['card', dir_ar == 'dir-rtl' ? 'text-end' : 'text-start']"
    v-if="news.status"
    @click="readMore"

  >
    <img
      v-if="news.image_url"
      class="card-img-top"
      :src="news.image_url"
      alt="News Image"
    />
    <img
      v-else
      class="card-img-top-placeholder"
      :src="placeholder"
      alt="News Image"
    />
    <div
      :class="['card-body', dir_ar == 'dir-rtl' ? 'text-end' : 'text-start']"
    >
      <!-- <p class="date">
        {{ formateDateAndTime(news.created_at, 'date') }}
      </p> -->
      <h4 class="card-title">{{ news.title }}</h4>
      <p
        class="card-text"
        v-html="news.descriptions[lang].substring(0, 100) + '...'"
      ></p>
      <button
        class="btn d-flex jsutify-content-around align-items-center"
        :class="[dir_ar == 'dir-rtl' ? 'ms-auto' : 'me-auto', fl_ar_rev]"
        @click="readMore"
      >
        <i v-if="dir_ar == 'dir-rtl'" class="fa fa-chevron-left"></i>
        {{ $t("read more") }}
        <i class="fa fa-chevron-right" v-if="dir_ar == 'dir-ltr'"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["news"],
  methods: {
    readMore() {
      this.$emit("readMore");
    },
  },
};
</script>

<style>
</style>