import Vue from 'vue';
import Vuex from 'vuex';
import sliders from "./sliders";
// import banners from "./banners";
import contsctUs from "./contact-us";
import pages from "./pages";
import subscribe from "./subscribe";
import sections from "./sections";
import services from "./services";
import settings from "./settings";
import news from "./news";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appLoading: true,
    controlledRoutes: [],

    serviceCards: [
      {
        id: 1,
        text: "استلام شهادة قنصلية",
        icon: require("../assets/images/cert.png"),
      },
      {
        id: 2,
        text: "تجديد بطاقه الرقم القومي",
        icon: require("../assets/images/ID.png"),
      },
      {
        id: 3,
        text: "استلام جواز سفر",
        icon: require("../assets/images/port.png"),
      },
      {
        id: 4,
        text: "موعد للزواج",
        icon: require("../assets/images/vows.png"),
      },

    ],
    trendingCards: [
      {
        availableAppointments: ['09:30 AM', '10:00 AM', '11:00 AM', '08:10 AM', '01:50 AM', '11:30 Am', '08:40 AM', '01:10 AM'],
        id: 9,
        text: "معامله مستعجله",
        icon: require("../assets/images/cert.png"),
        img: require("@/assets/images/service-card-1.png"),
      },
      {
        availableAppointments: ['09:30 AM', '10:00 AM', '11:00 AM', '08:10 AM', '01:50 AM', '11:30 Am', '08:40 AM', '01:10 AM'],
        id: 1,
        text: "استلام شهاده قنصليه",
        icon: require("../assets/images/cert.png"),
        img: require("@/assets/images/card2.png"),

      },
      {
        availableAppointments: ['09:30 AM', '10:00 AM', '11:00 AM', '08:10 AM', '01:50 AM', '11:30 Am', '08:40 AM', '01:10 AM'],
        id: 2,
        text: "تجديد بطاقه الرقم القومي",
        img: require("@/assets/images/card3.png"),
        icon: require("../assets/images/ID.png"),
      },
      {
        availableAppointments: ['09:30 AM', '10:00 AM', '11:00 AM', '08:10 AM', '01:50 AM', '11:30 Am', '08:40 AM', '01:10 AM'],
        id: 3,
        icon: require("../assets/images/ID.png"),
        text: "شهادة ميلاد بعد 3 شهور",
        img: require("@/assets/images/card1.png"),
      },
      {
        availableAppointments: ['09:30 AM', '10:00 AM', '11:00 AM', '08:10 AM', '01:50 AM', '11:30 Am', '08:40 AM', '01:10 AM'],
        id: 4,
        text: "استلام جواز سفر",
        img: require("@/assets/images/card4.png"),
        icon: require("../assets/images/port.png"),
        hints: [
          {
            question: "ما هي المستندات المطلوبة لاستصدار جواز سفر؟",
            answer: [
              "استيفاء نموذج  إصدار جواز سفر بالقنصلية.",
              "أصل وصورة بطاقة الرقم القومى لمن أتم 16 عاماً، أو أصل وصورة شهادة الميلاد المميكنة لمن هم دون ذلك. (وفى حاله تقديم شهادة الميلاد فقط يتم إصدار جواز السفر لمده 3 أعوام فقط)",
              "جواز السفر السابق وصورة منه، وفى حالة جواز السفر المفقود يقدم محضر شرطة موضحاً به سبب الفقد.",
              "الموقف من التجنيد للرجال لمن تجاوز عمره 18عاماً وبالنسبه للإناث المتزوجات (صورة من جواز سفر الزوج أو عقد الزواج أو إثبات إسم الزوج على الرقم القومى).",
              "عدد (4) صورة شخصية حديثة بخلفية بيضاء مقاس 4*6.",
              "رسوم إصدار جواز سفر:278 يورو للجواز الجديد 343  يورو لبدل الفاقد أو التالف.",
              "المدة التى تستغرقها هذه المعاملة: خلال ثلاثة أشهر."
            ],
          }
        ]
      },
      {
        availableAppointments: ['09:30 AM', '10:00 AM', '11:00 AM', '08:10 AM', '01:50 AM', '11:30 Am', '08:40 AM', '01:10 AM'],
        id: 5,
        text: "تقديم أوراق للزواج للمراجعة وعمل موعد",
        img: require("@/assets/images/card3.png"),
        icon: require("../assets/images/vows.png"),
        hints: [
          {
            question: "ما هي المستندات المطلوبة للزواج؟",
            answer: [
              "أصل وصورة شهادة ميلاد كل من الزوجين.",
              "أصل وصورة جواز سفر أو مستند تحقيق الشخصية للزوجين.",
              "شهادة قيد فردى للزوج أو الزوجة المصريين.",
              "إقرار من الزوجة بحالتها الإجتماعية.",
              "إن كان أحد الزوجين أجنبى، يجب تقديم شهادة عدم ممانعه من سلطات الدولة التابع لها.",
              "أربعة صور شخصية حديثة مقاس 4  x 6 لكل من الزوج و الزوجة.",
              "يتم إرسال كافة تلك المستندات على فاكس القنصلية رقم  . . . .  ليتم تحديد موعد للزواج بحضور الزوجين أو وكيلهما وشاهدين.",
              "2 شهود بجوازات سفر صالحة"
            ],
          },
          {
            question: "ما هي المستندات المطلوبة للتصادق على الزواج؟",
            answer: [
              "كافة المستندات السابقة، عدا شهادة عدم الممانعة للزوج أو الزوجة الأجنبيين، مع تقديم أصل عقد الزواج."
            ]
          }
        ]
      },
    ],
    newsList: [

    ],
  },
  mutations: {
    changeAppLoading(state, payload) {
      state.appLoading = payload;
    },
    setAppLoading(state, payload) {
      state.appLoading = payload
    },
    addToDynamicRoutes(state, payload) {
      state.controlledRoutes.push(payload)
    }
  },
  actions: {
  },
  getters: {
    controlledRoutes: (state) => state.controlledRoutes,
    appLoading: (state) => state.appLoading,
    serviceCards(state) {
      return state.serviceCards;
    },
    trendingCards(state) {
      return state.trendingCards;

    },
    popularTrendingCards(state) {
      const lastCards = [];
      const count = 4;
      for (let i = 0; i < count; i++) {
        lastCards.push(state.trendingCards[i]);
      }
      return lastCards;
    },
    newsList(state) {
      return state.newsList;
    },
    lastNews(state) {
      const lastNews = [];
      const count = 3;
      for (let i = 0; i < count; i++) {
        lastNews.push(state.newsList[i]);
      }
      return lastNews;
    },
  },
  modules: {
    sliders,
    // banners,
    contsctUs,
    pages,
    subscribe,
    sections,
    services,
    settings,
    news
  }

});
