<template>
  <div class="tweets-box"
  v-if="getSettings && getSettings.maps_api_key"
  :class="{ active: isOpened }">
    <div class="activator" @click="toggle">
      <img src="../assets/x.jpg" alt="X logo" />
    </div>
    <div class="content">
      <a
        class="twitter-timeline"
        :data-lang="$i18n.locale"
        data-width="400"
        data-height="600"
        :href="`https://twitter.com/${getSettings.maps_api_key}?ref_src=twsrc%5Etfw`"
      ></a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpened: false,
    };
  },
  watch: {
    getSettings() {
      if (this.getSettings && this.getSettings.maps_api_key) {
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        script.charset = "utf-8";
        script.async = true;
        document.body.appendChild(script);
      }
    },
  },
  methods: {
    toggle() {
      this.isOpened = !this.isOpened;
    },
  },
};
</script>
