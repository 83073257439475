import Axios from "./embassyInc";

export default {
    state: {
        sliders: null,
        viweSlider: [],
        banners: [],
        banner: {},
    },

    getters: {
        getSliders(state) {
            return state.sliders;
        },
        getVSlider(state) {
            return state.viweSlider;
        },
        Banner: (state) => state.banner,
        Banners: (state) => state.banners,
    },

    mutations: {
        setSliders(state, SlidersPayload) {
            state.sliders = SlidersPayload;
        },
        setVSlider(state, vTestiPayload) {
            state.viweSlider = vTestiPayload;
        },
        setBanner(state, payload) {
            state.banner = payload
        },
        setBanners(state, payload) {
            state.banners = payload
        },
    },
    actions: {
        loadSliders({ commit }) {
            return new Promise((resolve, reject) => {
                Axios.post(`/v1/website/sliders`)
                    .then(res => {
                        if (res.data.results != null) {
                            commit('setSliders', res.data.results);
                            resolve(res);
                        } else {
                            reject(res);
                        }
                    }).catch(err => {
                        reject(err);
                    });
            });
        },
        loadVSlider({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/v1/website/sliders/show/${payload.id}`).then(res => {
                    if (res.data.results != null) {
                        commit("setVSlider", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        },
        getBanner() {
            return new Promise((resolve, reject) => {
                Axios.post(`/v1/website/banners/show/${payload.id}`,{
                    status: 1
                }).then(res => {
                    if (res.data.results != null) {
                        commit("setBanner", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        },
        getBanners() {
            return new Promise((resolve, reject) => {
                Axios.post(`/v1/website/banners`,{
                    status: 1
                }).then(res => {
                    if (res.data.results != null) {
                        commit("setBanners", res.data.results);
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        },
    }
};
