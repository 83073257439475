<template>
  <div class="container py-5 my-5">
    <div class="loader mx-auto my-5"></div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>